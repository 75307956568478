.layoutDrawer {
  background-color: #285460;
  position: fixed;
  transition: $sidebar-transition;
  left: 0;
  top: 0;
  z-index: 10;
  // padding-top: 130px;
  padding-top: 90px;

  .omd-layout-drawer-wrapper {
    width: 320px;
    transition: $sidebar-transition;
    &.closed {
      width: 0;
    }
  }
  @media only screen and (max-width: 1680px) {
    .omd-layout-drawer-wrapper {
      width: 270px;
    }
  }

  .open-button {
    top: 45%;
    right: 0px;
    transform: translateX(100%);
    width: 17px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
    border-radius: 0px 10px 10px 0px;
    z-index: 1;

    i {
      transform: rotate(-90deg);
      margin-left: 0;
      margin-top: 2px;
      color: $theme-green;
      font-size: 18px;
      &.closed {
        transform: rotate(90deg);
        margin-left: -8px;
      }
    }
  }
  .close-button {
    i {
      font-size: 20px;
      cursor: pointer;
    }
  }
}
