.costSheetForm {
  table {
    tr {
      td,
      th {
        padding: 0px 5px;
        text-align: center;
        color: $gray;
      }
    }
  }
}
