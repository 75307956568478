.challan_main {
  max-width: 100%;
  height: 100px;
  background: #f7f7f7;
  margin-bottom: 20px;
  padding: 10px;

  .row {
    height: 100%;
    align-items: center;
  }
  h4 {
    text-align: left;
  }
  .close_button {
    background: #ba1313;
    color: #ffffff !important;
  }
}

.challan_main_top {
  max-width: 520px;
  margin: 0 auto;
  h5 {
    margin: 0px 0 15px;
  }
}

.bill_main {
  max-width: 100%;
  height: 100px;

  margin-bottom: 2px;
  padding: 5px;

  .row {
    height: 100%;
    align-items: center;
  }
  h4 {
    text-align: left;
  }
  .close_button {
    background: #ba1313;
    color: #ffffff !important;
  }
}

.button {
  text-align: right;
  .omdButton {
    margin: 0 0 0 20px !important;
    display: inline-block !important;
    min-width: inherit;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 20px;
    &:first-child {
      margin-left: 0 !important;
    }
  }
}

.challan-style {
  overflow-y: auto;
  height: 70vh;
  overflow-x: hidden;

  padding: 10px;
}

.bill-style {
  overflow-y: auto;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
}

.billing-style {
  overflow-y: auto;
  height: 70vh;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
}

.text-area-size {
  width: 98.5%;
}
.item_color_front_modal_form {
  .rodal-dialog {
    overflow: auto;
  }
}
.workorder_modal_formwrap {
  .omdButton {
    margin-top: 6px;
  }
}
.generalInfoForm_orderDetails {
  padding-top: 30px;
  h6 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .challan_main {
    height: auto;
  }
  .button {
    padding: 20px 0 10px;
  }
  .challan-style {
    padding: 10px 0;
  }
  .button {
    .omdButton {
      margin-left: 10px !important;
      padding: 5px 10px;
    }
  }
  .generalInfoForm_orderDetails {
    overflow-y: auto;
  }
}
@media only screen and (max-width: 768px) {
  .challan_main {
    text-align: center;
    h4 {
      text-align: center;
      margin-bottom: 20px;
    }
    .challan_main_top {
      .col-sm-12.col-md-6 {
        text-align: center !important;
      }
    }
  }
  .button {
    text-align: center;
  }
  .workorder_modal_formwrap {
    label {
      text-align: left;
    }
    .row.mb-4 {
      .col-sm-12.col-md-6:last-child {
        padding-left: 0.75rem !important;
        margin-top: 10px;
      }
    }
  }
}
