.singleThreadItem {
  .threadName {
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }
  .threadImg {
    object-fit: cover;
    overflow: hidden;
  }
  .groupUserThread {
    .threadImg {
      height: 50px;
      width: 50px;
    }
  }
  .singleUserThread {
    .threadInfo {
      .threadImg {
        height: 47px;
        width: 47px;
      }
    }
    .threadCounter {
      height: 38px;
      width: 38px;
      border: 1px solid $theme-green;
      border-radius: 50%;
    }
  }
}
