.userMenu {
  .user-details {
    color: #000;
    p {
      line-height: 1.2;
    }
    .user-name {
      font-size: 14px;
      i {
        margin-left: 10px;
        font-size: 14px;
        color: $theme-green;
      }
    }
    .user-job-title {
      font-size: 11px;
      font-weight: 300;
    }
  }
  .profile-dropdown {
    right: -10px;
    top: 75px;
    box-shadow: 0 0 2px 2px #f6f7fb;
    background-color: #fff;
    &:before,
    &:after {
      left: inherit;
      right: 10px;
    }
    li {
      display: block;
      text-align: left;
      padding-top: 10px;
      &:nth-child(3) {
        padding-bottom: 10px;
      }
      // &:nth-child(4) {
      //   border-top: 1px solid $light-semi-gray;
      // }
      &:first-child {
        padding-top: 0;
      }
      &:hover {
        a {
          color: $primary-color;
          transition: $sidebar-transition;
          svg {
            color: $primary-color !important;
            path,
            line {
              color: $primary-color !important;
            }
          }
        }
      }
      a {
        color: $theme-body-font-color;
        transition: $sidebar-transition;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          color: $theme-body-font-color !important;
          path,
          line {
            color: $theme-body-font-color !important;
          }
        }
      }
    }
  }
}
