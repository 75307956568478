//Main layout

//Item Card Layout

//Initial Configuration Layout
.initialConfigLayout {
  .sidebar {
    background-color: $theme-green-dark;
    height: 100vh;
    width: 635px;
    .brandInfo {
      img {
        max-width: 470px;
      }
    }
  }
  .pageBody {
    height: 100vh;
    .heading {
      h1 {
        font-size: 75px;
        margin-bottom: 0;
        line-height: 1;
      }
      p {
        font-size: 40px;
        font-weight: 500;
        color: $gray;
      }
    }
  }
}
