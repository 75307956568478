//Sidebar Order List
.sidebarOrderList {
  .actionAndTitle {
    color: $theme-green;
    .title {
      i {
        font-size: 20px;
      }
      h4 {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
  .filterButton {
    button {
      padding: 4px 8px;
    }
  }
  .orderList {
    height: calc(100vh - 305px);
    overflow: auto;
    padding-top: 8px;
  }
}
