.dashboard-page {
  .todaysTask {
    i {
      font-size: 20px;
    }
  }

  .dashboard-item-top-card {
    margin-bottom: 1.5rem;
  }
}
