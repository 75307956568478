.production-status-data {
  .upload-btn {
    color: #52ab57;
    border: 1px solid #52ab57;
    font-size: 12px;
    padding: 7px 15px;
    height: 38px;
    min-width: 113px !important;
    border-radius: 10px;
  }
  .upload-icon {
    height: 38px;
    width: 38px;
    color: #52ab57;
    border: 1px solid #52ab57;
    border-radius: 10px;
  }
  .upload-icon i {
    font-size: 14px;
  }
}
