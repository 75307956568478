.outputButtonsGroup {
  .groupButton {
    .iconButton {
      cursor: pointer;
      transition: $sidebar-transition;
      svg {
        color: $theme-green;
        margin-bottom: 4px;
      }
      &:hover {
        color: $theme-green;
      }
    }
  }
}
