.productionDataProgressbar {
  &:last-child {
    margin: 0;
  }
  .progressbarWrapper {
    .progressbar {
      height: 40px;
      background-color: $theme-green-dark;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.69);
      overflow: hidden;
      .totalProgress {
        height: 100%;
        background-color: $theme-green;
        display: block;
      }
    }
  }
}

.multiStepProgressbar1 {
  .step {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      background-color: gray;
      width: 100%;
      left: 50%;
      z-index: 1;
      top: 10px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    .activeDot,
    .completedDot {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid;
      display: inline-block;
      background-color: #fff;
      z-index: 2;
      position: relative;
    }
    .activeDot {
      border-color: $gray;
      &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        top: 3px;
        left: 3px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid $gray;
      }
    }
    &.active {
      .activeDot {
        &::after {
          background-color: $gray;
        }
      }
    }
    &.completed {
      &::after {
        background-color: $theme-green;
      }
    }

    h5 {
      color: $gray;
    }
    .customTooltip {
      width: 300px;
      background-color: $gray;
      color: $white;
      font-size: 18px;
      padding: 17px 24px;
      display: inline-block;
      border-radius: 20px;
      position: relative;
      bottom: -40px;
      opacity: 0;
      &::after {
        position: absolute;
        content: '';
        height: 35px;
        width: 35px;
        background-color: $gray;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      }
    }
    &:hover {
      .customTooltip {
        opacity: 1;
      }
    }
  }
}
