.createOrderForm {
  .quantityDetailButtons {
    .productSize {
      .sizeList {
        ul {
          border: 1px solid $gray;
          border-radius: 10px;
          margin-left: -8px;
          margin-right: -8px;
          max-width: 500px;
          padding-bottom: 8px !important;
          li {
            list-style: none;
            padding-left: 8px;
            padding-right: 8px;
            width: calc(100% / 5);
            span {
              height: 32px;
              width: 100%;
              background-color: $white;
              color: $theme-green;
              display: inline-block;
              border-radius: 10px;
              color: $theme-green;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $theme-green;
              cursor: pointer;
              transition: $sidebar-transition;
              &.selected,
              &:hover {
                background-color: $theme-green;
                border-color: transparent;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.productionStepForm {
  .step {
    .title {
      font-size: 25px;
      color: $gray;
    }
    .inputField {
      .selectInput {
        height: 55px;
        font-weight: 500;
        border-radius: 10px;
        font-size: 20px;
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
      .deleteButton {
        i {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
}
.taskComment {
  .commentHeader {
    background-color: #52ab57;
    color: #ffffff;
    box-shadow: 0px 0px 10px #52ab57;

    h6 {
      margin-bottom: 0;
    }
  }
  .commentUserImg {
    height: 55px;
    width: 55px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .commentMessage {
    width: 95%;
    color: $white;
    border-radius: 10px;
    background-color: $gray;

    h6 {
      font-weight: bold;
      margin-bottom: 0px;
      margin-right: 10px;
    }
    p {
      margin: 0px;
    }
  }
}

.selectWidth {
  @media only screen and (min-width: 576px) {
    flex: 0 0 auto;
    width: 100%;
  }
}
