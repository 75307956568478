.userOnboardingPage {
  .userInvitaionForm {
    .form-check-input {
      height: 25px;
      width: 25px;
      &:checked {
        background-color: $theme-green;
        border-color: $theme-green;
      }
      &:focus {
        box-shadow: none;
        border-color: $theme-green;
      }
    }
  }

  .timeAndActionForm {
    table {
      tbody {
        tr {
          td {
            border-bottom: 1px solid #52ab5750;
            padding-top: 5px;
            padding-bottom: 5px;
            input,
            select {
              max-width: 110px;
              width: 100%;
              border-radius: 5px;
              border: 1px solid $gray;
              font-size: 12px;
              text-align: center;
              color: $gray;
              &:focus {
                outline: none;
              }
            }
            input::-webkit-calendar-picker-indicator {
              margin: 0;
            }

            .actions {
              i {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
