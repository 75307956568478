.notifications {
  max-width: 796px;
  height: 70vh;
  z-index: 1;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;

  h4 {
    color: $medium-gray;
    font-weight: 500;
  }
  .notifications-body {
    height: calc(100% - 35px);
    .notifications-item {
      display: flex;
      padding: 10px 15px;
      border-radius: 15px;
      transition: $sidebar-transition;
      img {
        width: 50px;
      }
      .text {
        h5 {
          color: $medium-gray;
          font-weight: 500;
        }

        p {
          color: #636363;
          margin-right: 50px;
          margin-bottom: 5px;
        }
      }
      .right-side {
        span {
          font-size: 15px;
          font-weight: 500;
          color: $medium-gray;
        }
      }
      // &.unseen {
      //   background-color: #8d8c8c26;
      // }
      // &:hover {
      //   background-color: #8d8c8c26;
      // }
    }
  }
}
