.drawerMenu {
  .actions {
    i {
      font-size: 20px;
      &.fa-plus {
        color: $theme-green;
      }
      &.fa-ellipsis-v {
        color: $gray;
      }
    }
  }
}
