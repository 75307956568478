.signInPage .signInForm {
  width: 650px;
  padding: 130px 15px 0;
}

.signInPage .signInForm h3 {
  font-size: 1.75rem;
  font-weight: 400;
  margin-bottom: 30px;
  color: #3d3d3d;
}

.signInPage .signup a {
  margin-left: 10px;
}
.signInPage .input-wrap {
  margin-bottom: 15px;
}
.signInPage .input-wrap label {
  font-weight: 500;
  font-size: 15px;
}
.signInPage .btn.btn-primary {
  height: 42px !important;
  padding: 3px 10px;
}
.signInForm h5.signup {
  font-size: 16px;
}

@media only screen and (max-width: 992px) {
  .signInPage .signInForm {
    width: 100%;
  }
  .signInPage .signInForm h3 {
    font-size: 1.5rem;
  }
}