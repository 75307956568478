.headerMenu {
  &:hover {
    li {
      span {
        color: $theme-green;
      }
    }
  }
  li {
    list-style: none;
    span {
      font-size: 18px;
      transition: $sidebar-transition;
    }
    .headerDropdownMenu {
      right: 0;
      width: 200px;
      border: 1px solid $gray;
      background-color: $white;
      border-radius: 10px;
      transition: $sidebar-transition;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: $sidebar-transition;
        &:hover {
          color: $theme-green;
        }
        i,
        svg {
          font-size: 22px;
          width: 22px;
          height: 22px;
          text-align: right;
          color: $theme-green;
        }
        svg {
          margin-right: -3px;
        }
      }
    }
  }
}
