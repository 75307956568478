//order nav item
.orderNavItem {
  color: $white;
  border: 2px solid $theme-green;
  border-radius: 10px;
  position: relative;

  &.active {
    background-color: $theme-green;
  }

  .unseenAlert {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: $theme-green-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    right: 0;
    top: 0;
    transform: translate(40%, -40%);
    color: $white;
  }
  .id {
    font-size: 13px;
  }
  .timeAndQuantity {
    font-size: 12px;
    font-weight: 300;
  }
}
.drawerMenuItem {
  .menuTitle {
    color: $white;
    .title {
      i {
        font-size: 24px;
        transition: $sidebar-transition;
        color: $white;
      }
    }
  }
  .childrenWrapper {
    max-height: 0;
  }
  &.active {
    .menuTitle {
      .title {
        i {
          transform: rotate(90deg);
          color: $white;
        }
      }
    }
    .childrenWrapper {
      max-height: 10000px;
    }
  }
  .addNewUser {
    .icon {
      height: 47px;
      width: 47px;
      border: 1px solid $theme-green;
      border-radius: 50%;
    }
    .name {
      font-size: 18px;
    }
  }
}
