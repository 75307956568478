@import '../../../../assets/scss/style.scss';

.itemCardLayout {
  border-radius: 15px;
  background-color: $white;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  // padding: 0.75rem;
  border: 1px solid #bebebe;

  .titleAndOther {
    background-color: #f2f5f9;
    padding: 0.25rem 0.75rem 0.25rem 1rem;
  }
  .dashboard-card {
    h3 {
      &.fw-light {
        font-size: 17px;
        font-weight: 500 !important;
      }
    }
  }
}
