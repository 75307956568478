// MainLayOut
$mainLayout: #f2f5f9;

//Colors
$white: #fff;
$black: #000;
$gray: #707070;
$blue-dark: #255361;
// $blue-dark: #fff;

///Font Colors
$theme-body-font-color: #313131;

///Theme Colors
$primary-color: #115aec;
$light-semi-gray: #eff0f1;
$gray: #6e6e6e;
$medium-gray: #707070;
$theme-green-dark: #194802;
$theme-green: #52ab57;
$theme-red: #f30f07;
$theme-blue: #007bff;
$theme-yellow: #ffc107;

//sidabr
$sidebar-width: 255px;
$sidebar-transition: 0.3s;
