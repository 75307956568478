.signInPage {
  .login_logo {
    text-align: center;
    img {
      width: auto !important;
      max-height: 70px;
    }
  }
  .action-wrap {
    text-align: center;
    .btn {
      background: #54a958;
      border: none;
      padding: 5px 20px;
      margin-top: 20px;
    }
  }
}

.page-body {
  padding: 20px;
}
legend {
  background-color: #ddd;
  font-size: 18px;
  color: #6e6e6e;
  font-weight: 600;
  padding: 10px;
  display: block;
  margin-bottom: 30px;
  border-radius: 5px;
  text-transform: capitalize;
}
.main_header_wrap {
  .header_right {
    text-align: right;
    .user-job-title {
      text-align: right;
    }
    .nav-menus {
      display: inline-block !important;
      position: relative;
      .notifications {
        width: auto !important;
        min-width: 340px;
        right: 10px;
        height: auto;
        padding: 15px !important;
        border-radius: 10px;
        h4 {
          font-size: 18px;
          margin: 0 0 12px !important;
        }
      }
    }
  }
}
table {
  thead {
    tr {
      th {
        font-size: 14px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 14px;
      }
    }
  }
}
.commercialStoreInfo {
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;

  .storeValueInfoLeft {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 25px;
    margin-bottom: 25px;
    border-radius: 10px;
    cursor: pointer;

    .totalTitle {
      font-size: 17px;
    }
    .totalValue {
      font-weight: 500;
      font-size: 45px;
      span {
        font-size: 17px;
        font-weight: 400;
      }
    }
  }
  .storeValueInfoLeft:hover,
  .storeValueInfoLeft.active {
    background: #f1f1f1;
  }

  .storeValueInfoLeft:last-child {
    margin-bottom: 0;
  }

  .storeChartValueRight {
    background: #f1f1f1;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    .storeGraphTop {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .totalTitle {
        font-size: 19px;
        font-weight: 500;
      }
      .totalValue {
        font-size: 19px;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        border: 1px solid #000;
      }
    }
    .pieChartWrap {
      padding-top: 40px;
    }
  }
}

.timeAndActionMoalWrap {
  padding-top: 20px;
  table {
    tr {
      td {
        padding-right: 10px;
      }
    }
  }
  select,
  input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .inputboxwrap {
    margin-bottom: 10px;
    textarea {
      width: 100%;
      height: 120px;
      padding: 5px;
    }
    select,
    input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
    }
    label {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .mx-auto {
    margin: inherit !important;
  }
}

.costManagementWrapper {
  Form {
    width: 100%;
    .textInput {
      margin-bottom: 20px;
      box-shadow: none;
      border: 1px solid #6e6e6e;
      border-radius: 10px;
      height: 45px;
    }
    .addBtn {
      margin-top: 20px;
    }
  }
  .title {
    padding: 10px 0;
  }
  .costManagementBox {
    position: relative;
    .costButton {
      position: absolute;
      right: 25px;
      top: 22px;
      min-width: 65px;
    }
  }
  .sendrfq {
    border: 1px solid #54a958;
    color: #000 !important;
    margin-right: 10px;
    background: none !important;
  }
}

.rodal {
  .textInput.size-sm,
  .selectInput.size-md {
    height: 45px;
    font-size: 14px;
  }
  label {
    margin-bottom: 10px !important;
    font-size: 16px;
  }
}

.requestForQuotationForm {
  .profileImageUploadBox {
    margin: 5px;
  }
}

.costsheetRfqIcon {
  padding: 10px 0;
  a {
    display: inline-block;
    margin-left: 10px;
    text-align: center;
    i {
      color: #0ccf16;
      font-size: 22px;
    }
    span {
      display: block;
      font-size: 10px;
      color: #000;
    }
  }
}
.costSheetRfqTop {
  background: #54a958;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  h3 {
    font-size: 16px;
    color: #fff;
    margin: 8px 0 0;
  }
  .omdButton {
    background: #fff !important;
    color: #54a958 !important;
    border: 1px solid #bebebe;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 15px;
  }
}

.rfqDataPage {
  .itemCardLayout_itemCardLayout__1n65S {
    padding: 0;
    border-radius: 0 0 10px 10px;
  }
  table {
    thead {
      border-bottom: 1px solid #ccc;
      th {
        border-left: 1px solid #ccc;
        padding: 10px;
      }
    }
    tr {
      border-top: 1px solid #ccc;
      td {
        border-left: 1px solid #ccc;
      }
      &:first-child {
        border-top: none;
      }
    }

    .rfqInnerTable {
      tr {
        td {
          padding: 10px 0;
          border-left: none;
        }
      }
    }
    .qtyTable {
      tr {
        td {
          border-left: none;
          border-bottom: none;
        }
      }
    }
  }
}

.workorder_modal_formwrap {
  label {
    display: block;
    width: 100%;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
  }
  .input-group {
    margin-bottom: 22px;

    .textInput.size-md {
      border-radius: 5px;
      height: 40px;
      width: 100%;
      padding: 0 12px !important;
    }
    .inputTextarea.size-md {
      padding: 5px 10px !important;
      min-height: 120px;
    }
  }
  .delete_btn {
    float: right;
    border: none;
    font-size: 16px;
    color: red;
    background: none;
    margin-top: 10px;
  }
  .delete_btn_box {
    .delete_btn {
      margin-top: 45px;
    }
  }

  .color-theme-primary {
    border-color: #285460;
    color: #fff !important;
    background: #285460;
    font-size: 14px;
    min-width: inherit;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .btn_submit {
    margin-top: 20px;
  }
}

.add_new_record {
  .add_btn {
    background: #285460;
    border: none;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    background: none;
    cursor: pointer;
  }
  .fas {
    font-size: 13px;
    margin-right: 5px;
  }
}
.configuration_list_wrap {
  padding: 30px 0;
  table {
    border-bottom: none;
    tr {
      position: relative;
      border-bottom: 1px solid #dee2e6;
      td,
      th {
        padding: 5px 10px;
        font-size: 14px;
        line-height: 16px;
        border: none;
      }
      .btn_green_save {
        line-height: 16px;
      }
    }

    td.text-green {
      float: right;
    }
    .selectInput,
    .textInput {
      width: 100%;
      font-size: 14px;
      height: 40px;
      padding-right: 10px !important;
      padding-right: 10px !important;
    }
    tbody {
      tr:nth-child(odd) {
        background: var(--bs-table-bg);
      }
      tr:nth-child(even) {
        background: #fff;
      }
    }
  }
  .submitBox {
    text-align: center;
    padding-top: 20px;
  }
  .add_new_record {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: right;
    .add_btn {
      background: #285460;
      color: #fff;
      padding: 10px 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .config_top_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    vertical-align: middle;
  }
}
.configuration_form_modal {
  .add_new_record {
    text-align: left;
  }
}
.work_order_ittemlist_wrap {
  table {
    tr {
      td {
        padding: 12px 10px 5px !important;
        border: none;
        margin-bottom: 10px;

        @media only screen and (max-width: 1499px) {
          padding: 12px 6px 5px !important;
        }
      }
    }
  }
  .order_management_table {
    tr {
      td {
        padding-top: 0 !important;
      }
    }
  }
  .form_list_table {
    tr {
      th {
        @media (max-width: 1499px) {
          span {
            font-size: 12px !important;
          }
        }
      }
      td {
        a {
          font-size: 12px;
          margin: 0 5px;
          color: #484848 !important;
          border-bottom: 1px solid #484848;
          display: inline-block;
        }
      }
    }
  }
  .job_resample_box {
    .text-start {
      display: inline-block;
    }
    .resampleBox {
      width: 50%;
      float: left;
    }
  }
}

.btn_green {
  background: #285460 !important;
  border: 1px solid #285460;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
  margin: 5px 10px;
  padding: 5px 15px 3px;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  position: relative;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

.rodal {
  .btn_green {
    min-width: auto;
  }
  .btn_green_save {
    margin: 0 0 0 10px;
    height: 30px;
    padding: 5px 10px;
    line-height: 20px;
  }

  table {
    background: #f2f2f2;
    border: 1px solid #dee2e6;
    width: 100% !important;

    thead {
      background: #dddddd;
      color: #ffffff;
    }
  }
}
.configuration_table_wrap {
  table {
    border: 1px solid #dee2e6;
    width: 100% !important;
    background: #f2f2f2;

    thead {
      background: #dddddd;
      color: #000;
      text-transform: capitalize;
    }
    .card-table-body {
      td.text-green {
        white-space: nowrap;

        .config_edit_btn {
          .far {
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .config_table {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    padding-top: 20px;
    background: #fff;

    .rdt_TableHeader {
      display: inherit;
      min-height: inherit;
      text-align: center;
      // width: 50%;
      // float: left;
    }
    header.krRRkh {
      text-align: center;
      display: inherit;
      // width: 50%;
      // float: right;

      .textInput {
        margin: 0 auto;
        font-size: 14px;
        height: 40px;
      }
    }
    .kPRWrE {
      margin-top: 20px;
    }
  }
}
.btn_green_save {
  min-width: inherit;
  margin: 0px 3px;
  padding: 0px 5px 0px;
}
.orderInfoPage {
  padding: 20px;
}
.workorder_modal_formwrap {
  border: none !important;
}

.tag_iten_wrap {
  border: 1px solid #ddd;
  padding: 10px;
  .react-tags__selected-tag,
  .tag-iten {
    display: inline-block;
    padding: 0 5px;
    border: 1px solid #ddd;
    margin: 3px;
  }
  .react-tags__search-input,
  .addbox {
    width: inherit !important;
    padding: 3px;
    min-width: 10px !important;
    max-width: 86px;
    border: 1px solid #eee !important;
    outline: none;
    box-shadow: none;
    margin-top: 5px;
    :focus {
      border: 1px solid #eee !important;
    }
  }
}

.react-tags_suggestions {
  border: 1px solid #eee !important;
  padding: 10px !important;
  border-radius: 5px !important;
  background: red !important;
}

.form_list_table {
  .itemButtonBox {
    text-align: center;
    white-space: nowrap;
  }
  button {
    font-size: 12px;
    padding: 4px 8px;
    // padding: 4px 8px !important;
    margin: 0 3px 5px;
  }
  .btn_import {
    padding: 6px 10px;
    font-size: 12px;
  }
  .iconBox {
    text-align: right;
    padding-top: 5px;
    position: absolute;
    right: 25px;
  }
}
.userMenu {
  .notificationBell {
    height: 30px;
    margin: 18px 10px 0;
  }
  .img-80.onhover-dropdown {
    max-height: 60px;
    width: auto;
  }
  .object-fit-cover {
    max-height: 65px;
    width: auto;
    img {
      height: 60px !important;
      width: 60px !important;
    }
  }
}
.time_action_taskList_table {
  border-radius: 10px;
  margin: 0;
  overflow: hidden;
  thead {
    tr {
      th {
        border: none !important;
      }
    }
  }
  tr {
    th {
      background: #ddd;
      color: #000;
      white-space: nowrap;
    }
    th,
    td {
      padding: 10px;
    }
    .far.text-green {
      font-size: 15px !important;
      display: inline-block;
    }
  }
}

.rdt_Table {
  .rdt_TableHeadRow {
    background: #ddd;
    color: #000;
    font-weight: 600;
    font-size: 14px;
  }
}
.factoryKeyMetricsPage {
  .counterWithProgressBarCard {
    h3 {
      font-size: 25px;
    }
  }
}

.omdButton.variant-outlined.color-theme-primary.btn_submit_pro {
  background: #52ab57;
  color: #ffffff;
}

.orderHeader {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  padding-right: 15px;
  .userInfo {
    p {
      font-size: 20px;
    }
  }
}

.configuration_list_wrap {
  table {
    tr {
      td {
        .justify-content-end {
          justify-content: space-around !important;
        }
      }
    }
  }
}

.request_move_wrap {
  padding-bottom: 10px;
}
.job_resample_box {
  padding: 20px 0;
  span {
    display: block;
  }
}
.form_itemlist_wrap {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 992px) {
    display: inherit;
    text-align: center;
  }
  .formItem {
    @media only screen and (max-width: 992px) {
      display: inline-block;
      margin: 0 10px 5px;
    }
    a {
      color: #000;
      text-decoration: underline;
    }
  }
}

.work_order_ittemlist_wrap .no_more_tables {
  padding: 20px;
}

// Media css start
@media only screen and (min-width: 1700px) {
  .work_order_ittemlist_wrap {
    .form_list_table {
      tr {
        th {
          span {
            font-size: 16px !important;
          }
        }
        td {
          a {
            font-size: 16px;
          }
          .text-start {
            span {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }
  .form_list_table {
    button {
      font-size: 14px;
      padding: 6px 10px 4px !important;
    }
    .btn_import {
      padding: 6px 10px 4px !important;
      font-size: 14px;
    }
  }
}
.config_edit_btn,
.config_remove_btn {
  border: none;
  i {
    color: #54a958;
  }
}
.dashboard-card {
  h3 {
    font-size: 28px;
  }
}

.messangerLayout_messangerContent__75uW- {
  .chatBox_footerWrapper__26zSp {
    .undefined {
      float: right;
      .undefined {
        margin-right: 12px !important;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .todaysTask_root__3DRni {
    .todaysTask_todaysTaskWrapper__1ikPd {
      min-width: inherit !important;
    }
  }
  .configuration_list_wrap {
    table {
      tr {
        .text-green {
          min-width: 220px;
        }
      }
      .btn_green_save {
        min-width: auto;
      }
    }
  }
}

@media only screen and (max-width: 1680px) {
  .dashboard_statusbar {
    .statusbar_innerbox.mb-xxl-0 {
      padding: 0 8px !important;
    }
  }
  .drawerMenu {
    .drawerMenuItem {
      margin-top: 5px !important;
      .menuTitle {
        padding: 10px !important;

        .title {
          i {
            font-size: 18px;
          }
        }
      }
      h5 {
        font-size: 16px;
      }
      .childrenWrapper {
        a {
          .omdButton {
            font-size: 14px;
            padding: 5px;
          }
        }
      }
    }
  }
  .dashboard_statusbar {
    .statusbar_innerbox {
      padding: 0 8px;
    }
    h6 {
      font-size: 12px;
      line-height: 15px;
      //   height: 30px;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   display: -webkit-box;
      //   -webkit-line-clamp: 2;
    }
    h4 {
      margin: 0 !important;
    }
  }
  .configuration_list_wrap {
    table {
      tr {
        td,
        th {
          font-size: 12px;
          line-height: 16px;
        }
        td {
          word-break: break-word;
        }
      }
      .btn_green_save {
        font-size: 12px;
        padding: 3px 8px;
      }
    }
  }

  .todaysTask h5 {
    font-size: 16px;
  }
  .dashboard-card h3 {
    font-size: 24px;
  }
  .mainBadge.size-lg {
    font-size: 16px;
  }
  .qcUpdate .statusAndInfo .status .statusItem .title {
    font-size: 16px;
  }
  .qcUpdate {
    .chartBar {
      margin-top: 10px;
    }
  }

  .form_list_table {
    .text-start {
      span {
        font-size: 12px !important;
      }
    }
  }
  .time_action_taskList_table tr th {
    padding: 10px 5px !important;
    font-size: 12px !important;
  }
  .timeAndActionMoalWrap table tr td {
    white-space: inherit;
    word-break: break-word;
    min-height: 35px;
  }
}

@media only screen and (max-width: 992px) {
  .workorder_modal_formwrap {
    .input-group {
      .textInput {
        margin-left: 0 !important;
      }
    }
    .btn_submit {
      margin-bottom: 20px;
    }
  }
  .item_color_form {
    padding: 0 !important;
  }
  .work_order_ittemlist_wrap {
    table {
      tr {
        td {
          white-space: nowrap;
        }
      }
    }
  }
  .configuration_list_wrap table {
    .btn_green_save {
      margin-bottom: 5px;
    }
  }
  .dashboard-page {
    .todaysTask {
      i {
        float: right;
      }
    }
    .undefined {
      .ps-4 {
        padding-left: 0;
        padding-top: 20px;
      }
    }
  }
  .pageLayout_pageBodyWrapper__3PJja .pageLayout_pageBody__3rNsz {
    padding-right: 0 !important;
  }
  .mainLayout_contentWrapper__2cyRE {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  #page-body-and-drawer {
    padding: 0 15px;
  }
  .page-body {
    padding: 0;
  }

  .form_list_table {
    .itemButtonBox {
      text-align: center;
      display: flex;
      justify-items: center;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    .card-table-body {
      span {
        font-size: 12px !important;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .no_more_tables {
    padding: 10px 5px;
  }
  .timeAndActionMoalWrap .no_more_tables {
    padding: 5px;
  }
  .timeAndActionMoalWrap .no_more_tables table {
    border-radius: 10px;
  }

  /* Force table to not be like tables anymore */
  .no_more_tables table,
  .no_more_tables thead,
  .no_more_tables tbody,
  .no_more_tables th,
  .no_more_tables td,
  .no_more_tables tr {
    display: block;
  }
  .no_more_tables tr {
    margin-bottom: 15px;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .no_more_tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .no_more_tables tr {
    border: 1px solid #ccc !important;
    height: auto !important;
  }
  .no_more_tables td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 45% !important;
    white-space: normal;
    text-align: left;
    text-align: left !important;
    min-height: 25px;
  }
  .work_order_ittemlist_wrap .no_more_tables {
    padding: 0;
  }
  .work_order_ittemlist_wrap .no_more_tables tr td {
    padding-left: 45% !important;
    word-break: break-word;
    white-space: inherit;
  }
  .no_more_tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 7px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: inherit;
    text-align: left;
    font-weight: bold;
  }
  .no_more_tables td:before {
    content: attr(data-title);
  }
  .empty_td {
    display: none !important;
  }
  .configuration_table_wrap table .card-table-body td.text-green {
    white-space: inherit;
  }
  .configuration_list_wrap table td.text-green {
    float: inherit;
  }

  .no_more_tables table td.text-green {
    color: #212529 !important;
  }
  .time_action_taskList_table {
    thead {
      height: inherit !important;
    }
  }
  .no_more_tables .itemCardLayout_itemCardLayout__1W6Hm {
    border: none;
    border-radius: 0;
    background: none;
  }
  .time_action_taskList_table {
    border-radius: 0;
    background: #ffffff;
  }
  .empty_td {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main_header_wrap {
    padding: 0 20px;
    .header_logo {
      padding: 10px 0 0px;
      text-align: center;
      img {
        width: 200px !important;
      }
    }
    .header_right {
      text-align: center;
      .nav-menus {
        width: 100%;
        padding-left: 0;
        .userMenu {
          display: block !important;
        }
      }
    }
  }
  .userMenu {
    .img-80.onhover-dropdown {
      max-height: 45px;
      height: 45px;
    }
    .object-fit-cover {
      img {
        height: 45px !important;
        width: 45px !important;
      }
    }
  }
  .d-flex {
    display: inherit !important;
  }
  .status {
    .statusItem {
      text-align: center;
      margin-bottom: 30px;
      p.title {
        margin-bottom: 10px !important;
      }
    }
  }
  .production_capacity {
    margin-bottom: 10px;
  }
  .production_date {
    display: block !important;
    padding: 10px 0;
    span {
      display: block;
      margin-bottom: 10px !important;
    }
  }
  .order_searchbox {
    // display: block !important;
    margin-top: 35px !important;
    svg {
      right: 12px !important;
      top: 8px !important;
    }
    h5 {
      font-size: 14px;
    }
  }
  .order_search_innerbox {
    display: inherit !important;
    position: relative;
    input {
      width: 100% !important;
    }
    .search_inner_inputbox {
      position: relative;
    }
    .variant-outlined {
      top: -45px;
      right: 0;
      position: absolute;
    }
  }
  .work_order_ittemlist_wrap {
    .work_order_table_wrap {
      overflow: auto;
    }
  }
  .orderInfoPage {
    padding: 0;
    padding-top: 20px;
  }
  .rodal-zoom-enter {
    padding: 35px 20px 20px !important;
  }
  .timeAndActionModal {
    form {
      padding: 0 !important;

      .row.justify-content-between {
        display: inherit;
        .inputboxwrap {
          margin: 0;
          .col-sm-12 {
            padding: 0;
          }
        }
      }
    }
  }
  .configuration_list_wrap {
    table {
      tr {
        td,
        th {
          line-height: 16px;
          text-align: center;
        }
      }
    }
  }
  .mainLayout_contentWrapper__2cyRE {
    padding-left: 0 !important;
  }
  .orderHeader {
    padding-right: 10px !important;
  }
  .itemCardLayout_itemCardLayout__1W6Hm {
    // margin-top: 20px;
    padding: 0;
  }

  .work_order_ittemlist_wrap {
    table {
      thead {
        tr {
          th {
            font-size: 12px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .workorder_modal_formwrap {
    overflow-x: auto;

    form {
      overflow-x: auto;
      margin-bottom: 10px;
    }
  }
  .requisition_info_wrap {
    padding: 0 !important;
  }
  .layoutDrawer {
    padding-top: 120px;
  }

  .form_list_table {
    .card-table-body {
      span {
        padding-right: 55px !important;
        display: block;
      }
    }
  }

  .p-4 {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .configuration_list_wrap {
    table {
      tr {
        td {
          word-break: inherit;
        }
      }
    }
  }
  .request_move_wrap {
    display: inherit;
  }
  .request_move_left,
  .request_move_right {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 10px;

    button {
      display: inline-block !important;
      float: none !important;
    }
  }
  .request_move_left {
    text-align: left;
    float: left;
    width: auto;
    padding-left: 0;
    padding-top: 0;
  }

  .form_list_table {
    .iconBox {
      right: 0;
    }
  }

  .selectWidth {
    width: 100% !important;
  }
}
@media only screen and (max-width: 480px) {
  .orderHeaderInnerBox {
    width: 100%;
  }
  .order_searchbox {
    text-align: center;
    .order_search_box {
      float: none !important;
      margin-bottom: 10px;
    }
    .omdButton {
      margin: 0 auto;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

/* *****************main_contect_info_wrap start ************** */
.main_content_info_wrap {
  padding: 30px 20px 20px;
  overflow: hidden;
  h3 {
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: 500;
  }
  .text-green {
    color: #285460 !important;
  }
  .btn_trimps {
    color: #ffffff;
    background: #285460;
    padding: 0 15px;
    height: 35px;
    line-height: 23px;
    border-radius: 5px;
  }
  .add_new_btn {
    color: #285460 !important;
    background: none;
    border: 1px solid #285460;
    font-size: 15px;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    font-weight: 600;
    min-width: 70px;
  }
}

.default_table {
  border-radius: 6px;
  // overflow: hidden;
  background: #ffffff;

  tr {
    th,
    td {
      padding: 10px;
      color: #000;
      font-size: 14px;
    }
  }
  thead {
    background: #ddd;
    border-radius: 6px 6px 0 0;
    tr {
      th {
        border: none;
      }
    }
  }
  tbody {
    border: 1px solid #ddd;
    tr {
      td {
        input {
          text-align: left !important;
          font-size: 12px;
        }
      }
    }
  }
  .btn_action {
    .print {
      background: #285460;
      border: none;
      color: #ffffff;
      padding: 0px;
      border-radius: 3px;
      margin-right: 8px;
      width: 100px;
      height: 150px;
    }
  }
}

/* *****************bill_of_material_wrapper start ************** */
.bill_of_material_wrapper {
  form {
    .form_inner_box {
      label {
        margin-bottom: 10px !important;
      }
      select {
        font-size: 16px;
        height: 47px;
      }
      .bill_of_material_wrapper {
        .omdButton {
          background: #285460;
          border: none;
          color: #ffffff !important;
        }
      }
    }
  }
  .omdButton.size-rag {
    font-size: 18px;
    min-width: 200px;
    color: #ffffff;
    background: #285460;
  }
  .profile_tab_wrap {
    padding-top: 50px;
  }
  .profile_tab_wrap {
    .nav-tabs {
      background: #ffffff;
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      .nav-link {
        color: #212529;
        padding: 12px 20px;
      }
      .nav-link.active {
        background: #285460;
        color: #ffffff;
        border-radius: 5px 5px 0 0;
      }
    }
    .tab-content {
      .bg-light {
        background: none;
        padding: 10px 0 20px 0 !important;
        h4 {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
}

.profile_tab_inner_table_wrap {
  .profile_tab_table {
    margin-bottom: 20px;
    background: #ffff;
    thead {
      background: #ddd;
      tr {
        th {
          color: #000;
          padding: 10px 20px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;
        }
        td.text-green {
          color: #285460 !important;
          text-align: center;
        }
        .default_table {
          background: #ffffff;
        }
      }
    }
  }
}
/* *****************raw_material_category_wrap start ************** */
.raw_material_wrap {
  .raw_material_top_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .addMore {
      display: none;
    }
  }
}

/* *****************raw_material_category_wrap end ************** */

.accountBill {
  .accordion-header {
    .accordion-button {
      h5 {
        margin: 0;
        font-size: 18px;
      }
    }
  }
  .accordion-button:not(.collapsed) {
    color: #000000;
  }
  h5 {
    font-size: 18px;
    margin-bottom: 0;
  }
  .accountBill_topBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .rmdp-input {
      background: none;
      border: none;
      width: 166px;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
  .accordion-button:focus {
    box-shadow: none;
  }
}
