* {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 37px;
}
h3 {
  font-size: 35px;
}
h4 {
  font-size: 25px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 15px;
}

a {
  text-decoration: none;
}
