.customDrawer {
  z-index: -1;
  .bg {
    background-color: #00000020;
    transition: 0.2s;
  }
  .drawer {
    z-index: 20;
    transition: 0.2s;
    transition-delay: 0.2s;
  }
}
