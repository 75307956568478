.quantityChart {
  tr {
    height: 64px;
    td,
    th {
      font-size: 20px;
      color: $gray;
      border: 1px solid #e5e5e5;

      &:first-child {
        border-left: 0px;
        border-top: 0;
      }
      &:last-child {
        border-right: 0px;
        border-bottom: 0;
      }
    }
    tr {
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
    }

    th {
      font-weight: 500;
      border-top: none;
    }
    td {
      font-weight: 300;
    }
  }
  thead {
    tr {
      .size {
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            color: $theme-red;
            max-width: 0;
            overflow: hidden;
            margin-left: 0;
            transition: $sidebar-transition;
          }
          &:hover {
            i {
              max-width: 100%;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      input {
        border: none;
        width: 100%;
        color: $gray;
        font-weight: 300;
        &:focus {
          border: none;
          outline: none;
        }
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }

      .actions {
        i {
          font-size: 20px;
        }
        .delete {
          color: $theme-red;
        }
        .copy {
          color: $theme-blue;
        }
      }
    }
  }
  tfoot {
    td,
    th {
      border-bottom: none;
    }
  }
}
