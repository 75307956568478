body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.img-80 {
  height: 80px;
  width: 80px;
}

.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 10px 20px;
}
.p-30 {
  padding: 30px;
}
.p-100 {
  padding: 100px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}
.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.pl-20 {
  padding-left: 20px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-50 {
  padding-left: 50px;
}

.pr-30 {
  padding-right: 30px;
}
.pr-40 {
  padding-right: 40px;
}

.pt-10 {
  padding-top: 10px;
}
.pt-30 {
  padding-top: 30px;
}

.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-10 {
  margin-right: 10px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-80 {
  margin-right: 80px;
}

.mt-10 {
  margin-top: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-35 {
  margin-top: 35px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-180 {
  margin-bottom: 180px;
}

.h-25 {
  height: 25px;
}
.h-40 {
  height: 40px;
}
.h-60 {
  height: 60px;
}

.w-unset {
  width: unset;
}

.cursor-pointer {
  cursor: pointer;
}

.object-fit-cover {
  object-fit: cover;
}

.text-green {
  color: $theme-green !important;
}
.text-gray {
  color: $gray !important;
}
.text-red {
  color: $theme-red !important;
}
.text-blue {
  color: $theme-blue !important;
}

.bg-green {
  background-color: $theme-green !important;
}
.bg-gray {
  background-color: $gray !important;
}
.bg-red {
  background-color: $theme-red !important;
}
.bg-blue {
  background-color: $theme-blue !important;
}

.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;

  .onhover-show-div {
    position: absolute;
    z-index: 8;
    transition: all linear 0.3s;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .onhover-show-div {
      opacity: 1;
      transform: translateY(0px);
      visibility: visible;
    }
  }
}

table {
  width: 100%;
  font-size: 15px;
  thead {
    tr {
      height: 35px;
      th {
        font-weight: 700;
        padding: 5px;
      }
    }
  }
  tbody {
    tr {
      height: 30px;
    }
  }
  &.formTable {
    tr {
      height: 48px;
      td {
        padding: 5px;
      }
    }
    tbody {
      tr {
        td {
          select,
          input {
            max-width: 147px;
            width: 100%;
            display: inline-flex;
            &::-webkit-calendar-picker-indicator {
              margin-left: 0;
            }
          }
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
}
//Custom Scrollbar
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $theme-green;
  border-radius: 20px;
}

.sc-bdVaJa.iwSUvz {
  max-height: unset !important;
  width: 300px !important;
}

.dropdown-menu {
  padding: 0;
}

.rodal-close::before,
.rodal-close::after {
  background-color: $theme-red;
}
.rodal-close:hover:before,
.rodal-close:hover:after {
  background-color: #c40700;
}

.react-kanban-board {
  .react-kanban-column {
    &:nth-child(1) {
      background-color: #ffbf001a;
      .react-kanban-column-header {
        span {
          color: #ffb307;
        }
      }
    }
    &:nth-child(3) {
      background-color: #52ab571a;
      .react-kanban-column-header {
        span {
          color: $theme-green;
        }
      }
    }
    &:nth-child(4) {
      background-color: #f30f071a;
      .react-kanban-column-header {
        span {
          color: $theme-red;
        }
      }
    }
  }
}
