.calendarPage {
  .fc {
    a {
      color: #000;
    }

    .fc-button-primary {
      background-color: #52ab57;
      border-color: #52ab57;

      &:not(:disabled) {
        &:active {
          background-color: #194802;
        }
        &.fc-button-active {
          background-color: #194802;
        }
      }
    }
  }

  .taskGroupItem {
    padding-bottom: 4px;
    margin-bottom: 12px;
    border-bottom: 1px solid $medium-gray;

    &:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
      .taskData:last-child {
        margin-bottom: 0;
      }
    }
    .taskData {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      font-size: 13px;
      .time {
        width: 80px;
        border-right: 2px solid $gray;
      }
      .name {
        flex-grow: 1;
      }
      .actions {
        flex-shrink: 0;
        button {
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }
  }
}
