input,
select {
  &.is-error {
    border-color: $theme-red !important;
  }
}

.selectInput {
  border: 1px solid #d9d9d9 !important;
  text-align: left !important;
}
.textInput {
  background: #fff;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 300;
  border: none;
  color: #000000;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #54a958 !important;
  }
  &.size-sm {
    height: 38px;
  }
  &.size-md {
    height: 55px;
    font-size: 20px;
  }
  &.shadow-md {
    // box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid #d9d9d9 !important;
    &.is-invalid {
      // box-shadow: 0px 3px 10px rgba(245, 0, 0, 0.3);
      border: 1px solid #d9d9d9 !important;
    }
  }
  &.variant-outlined {
    box-shadow: none;
    border: 1px solid $gray;
  }

  &.is-error {
    border-color: $theme-red !important;
  }
  &::placeholder {
    color: #444 !important;
    opacity: 0.5;
  }
}

.selectInput {
  &.shadow-md {
    // box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid #d9d9d9 !important;
  }
  &.size-md {
    height: 55px;
    font-size: 20px;
  }
  &.size-sm {
    height: 38px;
    font-size: 12px;
  }
  &.variant-outlined {
    box-shadow: none;
    // border: 1px solid $gray !important;
  }
  &.is-error {
    border-color: $theme-red !important;
  }
}
