.qcUpdate {
  color: #000;
  .statusAndInfo {
    .info {
      p {
        font-weight: 500;
      }
    }
    .status {
      .statusItem {
        .title {
          font-size: 18px;
          font-weight: 500;
        }
        .mainBadge {
          min-width: 72px;
          text-align: center;
        }
      }
    }
  }
  .chartBar {
    margin-top: -30px;
    .pie {
      height: calc(100% - 55px);
      #apexcharts-radialbarTrack-0 {
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.55));
      }
    }
    .status {
      p {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}

.taskCommentCard {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scroll.example {
    -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .userImg {
    height: 58px;
    width: 58px;
    object-fit: cover;
    border: 2px solid #d1edf9;
    flex-shrink: 0;
    overflow: hidden;
  }
  .cardHeader {
    p {
      font-size: 15px;
      span {
        font-size: 18px;
      }
    }
  }
  .cardBody {
    .singleComment {
      h6 {
        font-size: 12px;
      }
      .comment-wrapper {
        .comment {
          border-radius: 10px;
        }
        p {
          font-size: 10px;
        }
      }
    }
  }
  .cardFooter {
    box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    .messageInput {
      border: 1px solid $theme-green;
      border-radius: 10px;
      overflow: hidden;
      textarea {
        resize: none;
        height: 50px;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
